
.container {
  .columns {
    margin: 0 !important;
    .column {
      padding: 20px;
      transition: all 0.3s;
      &.settings-list {
        padding-right: 30px;
        padding-top: 0;
        @media only screen and (max-width: 1200px) {
          width: 100%;
          border-right: 0;
          padding-right: 0;
        }
      }
      &.settings-content {
        padding-left: 30px;
      }
      @media only screen and (max-width: 1200px) {
        &.settings-content {
          z-index: 100;
          padding-left: 20px;
          width: 0;
          left: 110%;
          transition: all 0.5s;
          position: absolute;
          top: -60px;
          height: 100vh;
          background: #fff;
          -webkit-box-shadow: -1px -1px 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: -1px -1px 3px 1px rgba(0, 0, 0, 0.2);
          &.active {
            left: 10%;
            width: 90%;
          }
        }
      }
    }
  }
}

.setting-block {
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 30px 10px;
  & + .setting-block {
    border-top: 0;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  &.router-link-exact-active {
    background: #1d73f4;
    color: #fff;
    .title {
      color: #fff;
    }
  }
  .title {
    margin-bottom: 0;
  }
}

.settings-content {
  background: #fff;
  height: calc(100vh - 80px);
  ::v-deep input.input,
  ::v-deep select {
    background-color: #f0f0f0;
  }
}
